
.container1{
	max-width: 1170px;
	margin:100px;
}
.fot{
	padding:10px; 
	width:25%;
}
.row1{
	display: flex;
	flex-wrap: wrap;
	padding: 0;
}
ul{
	list-style: none;
	padding: 0px;
}
.footer1{
	background-color: black;
    padding: 3px 0 0 0;
	/* margin-top: 60px; */
	
}
.footer-col1{
   /* width: 23%; */
   padding: 0 32px;
}
.footer-col1 ul{
	padding-left: 0px;
}
.footer-col1 h4{
	font-size: 18px;
	color: #ffffff;
	text-transform: capitalize;
	margin-bottom: 35px;
	font-weight: 500;
	position: relative;
}
.footer-col1 h4::before{
	content: '';
	position: absolute;
	left:0;
	bottom: -10px;
	background-color: #e91e63;
	height: 2px;
	box-sizing: border-box;
	width: 50px;
}
.footer-col1 ul li:not(:last-child){
	margin-bottom: 10px;
}
.footer-col1 ul li a{
	font-size: 16px;
	text-transform: capitalize;
	color: #ffffff;
	text-decoration: none;
	font-weight: 300;
	display: block;
	transition: all 0.3s ease;
}
.footer-col1 ul li a:hover{
	color: #ffffff;
	padding-left: 6px;
}
.footer-col1 .social-links a{
	display: inline-block;
	height: 40px;
	width: 40px;
	background-color: rgba(255,255,255,0.2);
	margin:0 10px 10px 0;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	color: #ffffff;
	transition: all 0.5s ease;
}
.footer-col1 .social-links a:hover{
	color: #24262b;
	background-color: #ffffff;
}
.copyrights{
	background-color: #121212;
}
.copyrights p{
	margin: 0px;
	padding: 10px;
	font-size: 12px;
}

/*responsive*/
@media(max-width: 767px){
  .footer-col1{
    width: 50%;
    margin-bottom: 30px;
}
}
@media(max-width: 574px){
  .footer-col1{
    width: 100% !important;
	padding: 0px;
}
.footer-col1 ul{
	padding-left: 0px;
}
.copyrights p{
	font-size: 10px;
}
}