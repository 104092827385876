.maincolor {
  color: rgb(62, 147, 193) !important;
}

.greybg {
  background-color: rgb(217, 217, 217);
}

.mainmar {
  margin-bottom: 60px !important;
}

.serimg {
  width: 35%;
}

.fontp {
  font-size: 14px;
}
.footerlogo{
  width: 80%;
  background-color: rgb(207, 204, 204);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
}
.mainclass {
  background: #f2f2f2;
  padding: 60px !important;
  text-align: justify;
}

.stylelist1 {
  list-style-type: disc;
}

.padleft {
  padding-left: 40px !important;
}

.redbot {
  border-bottom: 3px solid #e91e63;
  margin: 0px 0px 15px 13px;
  width: 20% !important;
  opacity: 1;
}

.redbot1 {
  border-bottom: 3px solid #e91e63;
  margin-left: 406px;
  width: 20% !important;
  opacity: 1;
}

.redbot2 {
  border-bottom: 3px solid #e91e63;
  margin-left: 505px;
  width: 13% !important;
  opacity: 1;
}

.sliderposition {
  top: 35%;
  left: 25%;
  transform: translate(-50%, -50%);
  background: #00000030;
}

.bannerContent {
  padding: 30px !important;
}

.logostyle {
  width: 30%;
}

.ajaypimg {
  margin: auto;
  padding: 90px 40px 20px 40px !important;
  width: 80% !important;
}

.nav-link:hover {
  color: rgb(62, 147, 193) !important;
  /* text-decoration: underline; */
  border-bottom: 3px solid !important;
  border-color: rgb(62, 147, 193) !important;
}

.featurecard:hover {
  box-shadow: 1px 10px 12px #ccc;
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
}

.featurecard p {
  font-size: 12px;
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.featurecard button {
  background: #28a9e0;
}

.featurecard button:hover {
  background-color: #1e83ae;
  color: white !important;
}

.servicesa {
  background-color: rgb(62, 147, 193) !important;
  color: white !important;
}

.servicesa:hover {
  background-color: #0c5a7b !important;
}

.conmar {
  margin-bottom: 60px !important;
}

.conmar button {
  background: rgb(62, 147, 193);
}

.conbtn {
  margin-top: 3rem;
}

.conbtn:hover {
  background-color: rgb(171, 215, 239) !important;
}

.bgfortest {
  background: #ccc !important;
}

.test1 {
  max-width: 800px !important;
  background: #f2f2f2 !important;
}

.firsttest {
  width: 100%;
  display: flex;
  justify-content: center;
}

.testimonial {
  margin: 20px;
  width: 100%;
}

.innercard {
  /* background-color: rgb(217, 217, 217); */
  height: auto;
  padding: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  margin: 20px;
}

.innercard h3 {
  font-size: 14px;
  line-height: 2.5;
}

.innercard p {
  font-size: 12px;
}

.stylelist {
  list-style-type: disc;
  padding-left: 40px !important;
}

.expimg1 {
  width: 40% !important;
  padding-top: 40px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none !important;
}

.swiper-pagination-bullets {
  color: black !important;
  opacity: 1;

}

.carousel-indicators button {
  background-color: black !important;
}

.exp {
  padding: 4rem;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .exp {
    padding: 1rem !important;
    width: 100% !important;
  }

  .respmobile {
    position: absolute ;
    top: 40px;
    padding: 10px ;
    background: white ;
    width: fit-content ;
    z-index: 1000 ;
  }

  .respmobile1 {
    flex-direction: column !important;
    position: relative !important;
  }

  .exp li {
    padding-top: 9px !important;
    /* list-style: disc !important; */
  }

  .redbot1 {
    margin-left: 45px !important;
    width: 65% !important;
  }

  .redbot2 {
    margin-left: 75px !important;
    width: 45% !important;
  }

  .expimg {
    margin-top: 0px !important;
    display: flex !important;
    justify-content: center !important;
    padding: 0px 0px 40px 0px !important;
  }

  .ajaypimg {
    margin: auto;
    padding: 0px !important;
    width: 45% !important;
  }

  .bannerContent {
    padding: 10px !important;
  }

  .bannerContent h4 {
    font-size: 12px !important;
  }

  .conbtn {
    padding: 0.25rem 0.5rem !important;
    font-size: .600rem !important;
    line-height: 1.5 !important;
    margin-top: 0px !important;
  }

  .bannerh4span {
    font-size: 14px;
  }

  .Servicebgimg {
    background-size: cover !important;
    padding: 0px !important;
    border-radius: 30px !important;
    width: 100% !important;
  }

  .bgcolor {
    border-radius: 30px !important;
  }

  .h4title {
    font-size: 14px !important;
  }

  .locationstyle {
    width: 370px;
  }

  .logostyle {
    width: 50%;
  }

  .resheader {
    margin-right: 0px !important;
  }
}