.abt {
    width: 100%;
    font-weight: 700;
    height: 100%;
    margin-bottom: 60px;
}
.twocards{
    padding: 40px !important;
}
.twocardspad{
    padding: 20px!important;
}
.twocards p{
    font-weight: normal!important;
}
.docinfo p {
    font-size: 14px;
    text-align: justify;
    font-weight: initial;
}

.docinfo {
    right: 0px;
    padding: 60px 20px;
}

.docimg1 {
    width: 90%;
    padding: 40px 0px 0px 60px;
}